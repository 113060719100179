<template>
  <div class="singleRobot">
    <div class="banner">
      <div class="bannerBox">
        <div class="name">智能送物机器人</div>
      </div>
    </div>
    <!-- 相关参数 -->
    <div class="paramBox">
      <div class="param">
        <div class="title">智能送物机器人</div>
        <div class="paramTit">相关参数</div>
        <div class="pas">
          <div class="li">
            <div class="pasName">整机尺寸（长×宽×高）:</div>
            <div class="pasCont">685mm×590mm×1130mm</div>
          </div>
          <div class="li">
            <div class="pasName">柜子尺寸（长×宽×高）:</div>
            <div class="pasCont">335mm×300mm×180mm（上）335mm×300mm×250mm（下）</div>
          </div>
          <div class="li">
            <div class="pasName">重量:</div>
            <div class="pasCont">65kg</div>
          </div>
          <div class="li">
            <div class="pasName">屏幕尺寸（操作屏&液晶屏）:</div>
            <div class="pasCont">10.1寸&21.5寸</div>
          </div>
          <div class="li">
            <div class="pasName">分辨率:</div>
            <div class="pasCont">1920×1080</div>
          </div>
          <div class="li">
            <div class="pasName">导航方式:</div>
            <div class="pasCont">slam导航</div>
          </div>
          <div class="li">
            <div class="pasName">无线通讯:</div>
            <div class="pasCont">WiFi+4G</div>
          </div>
           <div class="li">
            <div class="pasName">最大负重/最大爬坡度:</div>
            <div class="pasCont">10kg/8度</div>
          </div>
           <div class="li">
            <div class="pasName">最大过障能力:</div>
            <div class="pasCont">2cm</div>
          </div>
           <div class="li">
            <div class="pasName">续航时间:</div>
            <div class="pasCont">12h</div>
          </div>
           <div class="li">
            <div class="pasName">系统:</div>
            <div class="pasCont">Android&ROS</div>
          </div>
           <div class="li">
            <div class="pasName">平台:</div>
            <div class="pasCont">NVIDIA JETSON</div>
          </div>
           <div class="li">
            <div class="pasName">电池容量:</div>
            <div class="pasCont">26Ah</div>
          </div>
           <div class="li">
            <div class="pasName">额定输入电压/额定输出电压:</div>
            <div class="pasCont">42V</div>
          </div>
           <div class="li">
            <div class="pasName">额定功率:</div>
            <div class="pasCont">126W</div>
          </div>
           <div class="li">
            <div class="pasName">传感器:</div>
            <div class="pasCont"> 声呐、碰撞等</div>
          </div>
           <div class="li">
            <div class="pasName">运动速度:</div>
            <div class="pasCont">0-1.5m/s（可设置）</div>
          </div>
           <div class="li">
            <div class="pasName">最小通过宽度:</div>
            <div class="pasCont">0.9m</div>
          </div>
        </div>
        <div class="text">
          <div
            class="tx"
          >智慧餐厅系统是一个从线上预订、点餐、支付的餐厅解决方案，机器人主要负责在餐厅进行配送工作，将餐食送到餐桌，在餐厅复杂的环境中行走，机器人需要有灵活的避障能力和稳定的行走解决方案。</div>
        </div>
        <div class="star">* 以上所有数据来自BOT实验室，依据测量方式不同,实际结果可能略有差异。</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  }
};
</script>
<style lang="less" scoped>
// banner
.banner {
  width: 100%;
  height: 6rem;
  background: url("../../../assets/product/robot.png");
  background-size: 100% 100%;
  .bannerBox {
    width: 12rem;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    .name {
      display: block;
      text-align: left;
      font-size: 0.48rem;
      line-height: 0.48rem;
      font-weight: bold;
      color: #333333;
    }
  }
}
// 相关参数
.paramBox {
  width: 100%;
  background-color: #fff;
  .param {
    width: 12rem;
    margin: 0 auto;
    padding-bottom: 1.2rem;
    text-align: left;
    .title {
      padding: 0.39rem 0px;
      font-size: 0.36rem;
      line-height: 0.36rem;
      font-weight: 400;
      color: #ff9000;
      border-bottom: 1px solid #333;
    }
    .paramTit {
      margin-top: 0.38rem;
      font-size: 0.36rem;
      line-height: 0.36rem;
      font-weight: bold;
      color: #333;
    }
    .pas {
      margin-top: 0.79rem;
      display: flex;
      text-align: left;
      flex-direction: column;
      .li {
        display: flex;
        flex-direction: row;
        margin-bottom: 0.38rem;
        font-size: 0.24rem;
        line-height: 0.24rem;
        font-weight: 400;
        color: #333333;
        &:last-of-type {
          margin-bottom: 0px;
        }
        .pasName {
          width: 3.7rem;
          margin-right: 1.16rem;
        }
      }
    }
    .text {
      margin-top: 0.35rem;
      border-top: 1px solid #333;
      padding-top: 0.4rem;
      text-align: justify;
      font-size: 0.24rem;
      font-weight: 400;
      color: #333333;
      line-height: 0.4rem;
    }
    .star {
      margin-top: 0.8rem;
      text-align: left;
      font-size: 0.1rem;
      line-height: 0.1rem;
      font-weight: 300;
      color: #333333;
    }
  }
}
</style>
